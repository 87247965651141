<template>
    <div>
        <nl-loader v-if="preloading" :show="true" />

        <template v-else>
            <ButtonRouterTo to="/partner/sites" label="Retour aux sites" size="is-small" icon-left="backward" />
            <div class="box">
                <NlTabs :tabs="tabs">
                    <template #default="props">
                        <PartnerUrlTable
                            v-if="props.openOnce[props.tab.index]"
                            :table-update="forceUpdate"
                            :status="props.tab.type"
                            @updateCounts="getCounts"
                        />
                    </template>
                </NlTabs>
            </div>
        </template>
    </div>
</template>

<script>
    import NlTabs from '@/components/UI/Tabs/NlTabs.vue'
    import { mapActions } from 'vuex'
    import PartnerUrlTable from '@/components/PartnerSites/Urls/PartnerUrlTable.vue'
    import ButtonRouterTo from '@/components/UI/Button/ButtonRouterTo.vue'

    export default {
        name: 'PartnerUrls',
        components: { ButtonRouterTo, PartnerUrlTable, NlTabs },
        data() {
            return {
                preloading: true,
                forceUpdate: false,
                counts: {
                    total: 0,
                    saleable: 0,
                    not_saleable: 0,
                    not_scrapped: 0
                }
            }
        },
        computed: {
            tabs() {
                return [
                    {
                        type: 'all',
                        label: `Tous (${this.counts.total.toLocaleString('fr-FR')})`,
                        icon: 'list',
                        disabled: this.counts.total === 0,
                        index: 0
                    },
                    {
                        type: 'saleable',
                        label: `Vendables (${this.counts.saleable.toLocaleString('fr-FR')})`,
                        icon: 'check',
                        disabled: this.counts.saleable === 0,
                        index: 1,
                        status: true
                    },
                    {
                        type: 'not_saleable',
                        label: `Invendables (${this.counts.not_saleable.toLocaleString('fr-FR')})`,
                        icon: 'times',
                        disabled: this.counts.not_saleable === 0,
                        index: 2
                    },
                    {
                        type: 'not_scrapped',
                        label: `Non scrappées (${this.counts.not_scrapped.toLocaleString('fr-FR')})`,
                        icon: 'clock',
                        disabled: this.counts.not_scrapped === 0,
                        index: 3
                    }
                ]
            }
        },
        created() {
            this.getCounts()
        },
        methods: {
            ...mapActions('urls', ['getStatusCountsByPartnerSite']),

            getCounts() {
                this.getStatusCountsByPartnerSite({ site_id: this.$route.params.id })
                    .then(response => {
                        this.counts = response
                    })
                    .finally(() => {
                        this.preloading = false
                    })
            }
        }
    }
</script>
